import React from "react"

import Layout from "../../components/layout-static"
import Seo from "../../components/seo"

const Page = () => {
  const metadata = {
    title: "Best CT Accident Attorney - Bartlett & Grippe",
    description:
      "Looking for the best Connecticut accident attorney? Need help figuring out where to start? Consider these 12 questions to find the right legal representation for your case.",
    heroH1: "Best CT Accident Attorney",
    heroH2: "When experience, integrity, and results matter.",
  }
  return (
    <Layout metadata={metadata}>
      <Seo title={metadata.title} description={metadata.description} />
      <div
        id="content"
        className="flex flex-wrap container mx-auto px-4 xl:px-0"
      >
        <div className="w-full pt-8 xl:pt-0">
          <div className="mb-16">
            <h2 className="mb-8">
              Finding the best Connecticut accident attorney can be difficult.
            </h2>
            <h3>Consider the following 12 questions:</h3>
            <ul className="list-decimal list-outside ml-6 mb-8">
              <li>
                <strong>Commitment</strong> – Will the accident attorney devote
                the many hours of detailed work required or will the case be
                handed off to an associate with less experience? Continuity from
                start to finish is critically important if you are to maximize
                the value of your case.
              </li>
              <li>
                <strong>Experience</strong> – Has the accident attorney handled
                hundreds of accident cases that they have learned from?
                Experience can make a big difference.
              </li>
              <li>
                <strong>Trials</strong> – Does the accident attorney handle
                trials, or does he refer cases that require a trial to other
                attorneys? Has the accident attorney successfully defended his
                clients’ verdicts in appeals to the Appellate and Supreme Court?{" "}
              </li>
              <li>
                <strong>Experts</strong> – Is the firm willing to devote its
                resources to retain experts to build a strong case on your
                behalf? Having a knowledgeable and experienced expert can be the
                deciding factor in your case.
              </li>
              <li>
                <strong>Knowledge</strong> – Can the firm analyze complicated
                medical records? Having a medical expert on staff that knows
                what to look for based on years of medical experience can affect
                the value of your case.
              </li>
              <li>
                <strong>Recognition</strong> – How is the attorney ranked by
                national rating agencies such as Avvo and Super Lawyers? A
                lawyer’s experience is key.
              </li>
              <li>
                <strong>Insight</strong> – Does the accident attorney have a
                diverse background which includes working as a defense attorney
                for insurance companies and do they represent any insurance
                companies now? Knowledge of the process and the tactics used by
                insurance companies helps to level the playing field between
                injured parties and insurance companies.
              </li>
              <li>
                <strong>Compassion</strong> – Will the accident attorney spend
                the time answering your questions and asking the right questions
                about how the accident affects you financially, emotionally,
                physically, and how the accident limits your daily activities?
                Having a caring and compassionate attorney on your side will
                ease the process and strengthen your case.
              </li>
              <li>
                <strong>Preparation</strong> – Does the accident attorney
                prepare for every case as if it will ultimately go to trial, and
                if it does, will they be the attorney representing you in court?
                Favorable settlements don’t just happen on their own. Hard work
                and preparation are necessary to be successful at trial and to
                position your case for a favorable settlement.
              </li>
              <li>
                <strong>Personality</strong> – Will a jury relate to and trust
                this accident attorney? This can greatly change your outcome.
                Cases often hinge on whether the jury likes the attorney. If you
                find the attorney personable and likable so will the jury.
              </li>
              <li>
                <strong>Client Satisfaction</strong> – Does the accident
                attorney have positive reviews from past clients?
              </li>
              <li>
                <strong>Results</strong> – Most noteworthy, does the accident
                attorney have proven results in court? If an insurance company
                respects your attorney’s reputation, it should result in higher
                compensation for you.
              </li>
            </ul>
            <p className="mb-8">
              The above questions are just a few of the many things you should
              ask yourself before choosing an accident attorney. Call Bartlett &
              Grippe now. 203-439-7717
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page
